let claimNumberDetailsState = {
  loading: false,
  hasClaimNumbersAdded: false,
  errors: {},
};

const claimNumberDetailsReducer = (state = claimNumberDetailsState, action) => {
  switch (action.type) {
    case 'FETCH_CLAIM_NUMBERS_DETAILS_START':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_CLAIM_NUMBERS_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        hasClaimNumbersAdded: action.payload.hasClaimNumbersAdded,
        errors: {},
      };
    case 'FETCH_CLAIM_NUMBERS_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
export default claimNumberDetailsReducer;
